
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  name: 'SignWithButtons',
  components: {
    TmButton,
  },
  props: {
    showSso: {
      type: Boolean,
    },
    googleFullWidth: {
      type: Boolean,
    },
    googleLabel: {
      type: String,
      default: 'Google',
    },
    label: {
      type: String,
      default: 'or continue with:',
    },
    reverse: {
      type: Boolean,
    },
  },
})

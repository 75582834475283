
import { Form as Validation } from 'vee-validate'
import { defineComponent, reactive, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import { useRoute } from 'vue-router'
import SignWithButtons from '@/components/pages/auth/SignWithButtons.vue'
import TmScrollbar from '@/components/shared/TmScrollbar.vue'
import SignupPassword from '@/components/shared/SignupPassword.vue'

export default defineComponent({
  components: {
    SignupPassword,
    TmScrollbar,
    TmFormLine,
    TmButton,
    SignWithButtons,
    Validation,
  },
  setup() {
    const route = useRoute()

    const email = ref(route.params.email || '')

    const showTooltip = ref(false)

    const signup = reactive({
      firstName: '',
      lastName: '',
      password: '',
      country: {
        name: 'United States',
        id: 'us',
      },
    })

    const logoLine1 = [
      { src: require('@/assets/images/signup/patronus.svg') },
      { src: require('@/assets/images/signup/Solvero.svg') },
      { src: require('@/assets/images/signup/cctv-aware.svg') },
      { src: require('@/assets/images/signup/nforto.svg') },
      { src: require('@/assets/images/signup/qiq.svg') },
      { src: require('@/assets/images/signup/ixica.svg') },
    ]
    const logoLine2 = [
      { src: require('@/assets/images/signup/cataphract.svg') },
      { src: require('@/assets/images/signup/market-home.svg') },
      { src: require('@/assets/images/signup/whalan.svg') },
      { src: require('@/assets/images/signup/maidpro.svg') },
      { src: require('@/assets/images/signup/loomion.svg') },
    ]

    return {
      email,
      showTooltip,
      logoLine1,
      logoLine2,
      signup,
    }
  },
})
